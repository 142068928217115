import React from "react";
import "./ProjectLanguages.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function ProjectLanguages(props) {
  const displayLogos = (logo) => {
    if (logo.iconifyClass === 'logos-raspberrypi')
      return (
        <img 
          alt=""
          style={{width: '30px', marginBottom: '-3px'}} 
          src="https://upload.wikimedia.org/wikipedia/fr/thumb/3/3b/Raspberry_Pi_logo.svg/810px-Raspberry_Pi_logo.svg.png"
        />
      )
    else if (logo.iconifyClass === 'logos-ionic')
      return (
        <img 
          alt=""
          style={{width: '30px', marginBottom: '-3px'}} 
          src="https://www.svgrepo.com/show/353912/ionic-icon.svg"
        />
      )
    else if (logo.iconifyClass === 'logos-heroku')
      return (
        <img 
          alt=""
          style={{width: '30px', marginBottom: '-3px'}} 
          src="https://cdn-icons-png.flaticon.com/512/873/873120.png"
        />
      )
    else if (logo.iconifyClass === 'logos-ovh')
      return (
        <img 
          alt=""
          style={{width: '35px', marginBottom: '-3px'}} 
          src="https://static-00.iconduck.com/assets.00/ovh-icon-2048x2048-l4c3izvg.png"
        />
      )
    else if (logo.iconifyClass === 'logos-mongodb')
      return (
        <img 
          alt=""
          style={{width: '30px', marginBottom: '-3px'}} 
          src="https://cdn.icon-icons.com/icons2/2415/PNG/512/mongodb_original_logo_icon_146424.png"
        />
      )
    else if (logo.iconifyClass === 'logos-arduino')
      return (
        <img 
          alt=""
          style={{width: '35px', marginBottom: '-3px'}} 
          src="https://seeklogo.com/images/A/arduino-logo-BC7CBC1DAA-seeklogo.com.png"
        />
      )
    else if (logo.iconifyClass === 'logos-chatgpt')
      return (
        <img 
          alt=""
          style={{width: '35px', marginBottom: '-3px'}} 
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/ChatGPT_logo.svg/1024px-ChatGPT_logo.svg.png"
        />
      )
    else if (logo.iconifyClass === 'logos-wordpress')
      return (
        <img 
          alt=""
          style={{width: '35px', marginBottom: '-3px'}} 
          src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/WordPress_blue_logo.svg/1024px-WordPress_blue_logo.svg.png"
        />
      )
    else if (logo.iconifyClass === 'logos-stripe')
      return (
        <img 
          alt=""
          style={{width: '35px', marginBottom: '-1px'}} 
          src="https://freelogopng.com/images/all_img/1685814539stripe-icon-png.png"
        />
      )
    else
      return (
        <span
          className="iconify"
          data-icon={logo.iconifyClass}
          data-inline="false"
        />
      )
  }

  return (
    <div>
      <div className="software-skills-main-div">
        <ul className="dev-icons-languages">
          {props.logos.map((logo) => {
            return (
              <OverlayTrigger
                key={logo.name}
                placement={"top"}
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    <strong>{logo.name}</strong>
                  </Tooltip>
                }
              >
                <li
                  className="software-skill-inline-languages"
                  name={logo.skillName}
                >
                  {displayLogos(logo)}
                </li>
              </OverlayTrigger>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default ProjectLanguages;
